import { Button, ButtonVariant, CheckIcon, ExpandIcon } from '@estimateone/frontend-components';
import { useUpgradeModalLinks } from './useUpgradeModalLinks';
import {
  UpgradeModalProps,
  UpgradeModalVideo,
  UpgradeModalWrapper,
  UpgradeModalContent,
  UpgradeModalCloseButton,
} from '@shared/UpgradeModal';
import { AnalyticsTrackedParam, SourceTrackingToken } from '@ascension/enums/Analytics';
import styles from './styles.scss';

type QuoteViewedBuilderUpgradeModalProps = { translatedBuilderTerm: string } & UpgradeModalProps;

export const QuoteViewedBuilderUpgradeModal = ({
  isOpen,
  onRequestClose,
  hideClose = false,
  useTransparentOverlay = false,
  translatedBuilderTerm,
}: QuoteViewedBuilderUpgradeModalProps) => {
  const { videoURL, imgURL, alt, title } = useUpgradeModalLinks();
  const upgradeUrl = `/account/upgrade/?${AnalyticsTrackedParam.IN_APP_SOURCE}=${SourceTrackingToken.UPGRADE_ROADBLOCK_LEADS_SPECIFINDER}`; // TODO TJ change this

  return (
    <UpgradeModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      hideClose={false}
      useTransparentOverlay={useTransparentOverlay}
    >
      <UpgradeModalContent>
        <div>
          <div className={styles.modalTitle}>
            Track the progress of your quotes and remove the guesswork
          </div>
          <div className={styles.modalIncluded}>Included in upgraded plan:</div>
          <div className={styles.modalCheckList}>
            <CheckIcon className={styles.modalCheckIcon} />
            See which {translatedBuilderTerm} are viewing your quotes to make proactive follow-up
            decisions
          </div>
          <div className={styles.modalCheckList}>
            <CheckIcon className={styles.modalCheckIcon} />
            Access a directory of {translatedBuilderTerm} nationally to establish new relationships
            and grow your business
          </div>
          <div className={styles.modalCheckList}>
            <CheckIcon className={styles.modalCheckIcon} />
            Boost your chances of winning with access to all tendering {
              translatedBuilderTerm
            } on every project
          </div>
        </div>
        <Button
          variant={ButtonVariant.Electric}
          type="button"
          fullWidth
          className={styles.explorePlanButton}
          href={upgradeUrl}
          target="__blank"
        >
          Explore Plans
          <ExpandIcon className={styles.expandIcon} />
        </Button>
      </UpgradeModalContent>
      <UpgradeModalVideo
        url={videoURL}
        posterImage={imgURL}
        altDescription={alt}
        videoTitle={title}
      />
      {!hideClose && <UpgradeModalCloseButton onRequestClose={onRequestClose} />}
    </UpgradeModalWrapper>
  );
};

import { useCallback, useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  IconSize,
  UpgradeIcon,
} from '@estimateone/frontend-components';
import { useCountryTermTranslator } from '@ascension/components/localisation';
import { QuoteViewedBuilderUpgradeModal } from './QuoteViewedBuilderUpgradeModal';
import { useCanSeeUpgradeHookForQuoteViewedInQuotesPage } from '../List/hooks';
import styles from './styles.scss';

export const QuoteViewedBuilderUpgradeHook = () => {
  const canSeeUpgradeHook = useCanSeeUpgradeHookForQuoteViewedInQuotesPage();
  const translate = useCountryTermTranslator();
  const translatedBuilderTerm = translate('builders');

  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const handleCloseModal = () => setUpgradeModalOpen(false);
  const showUpgradeModal = useCallback(() => {
    setUpgradeModalOpen(true);
  }, [setUpgradeModalOpen]);

  if (!canSeeUpgradeHook) {
    return null;
  }

  return (
    <>
      <section className={styles.quoteViewedBuilderUpgradeHook}>
        <p className={styles.description}>
          Track when {translatedBuilderTerm} view your quotes to stay ahead on progress
        </p>
        <Button
          variant={ButtonVariant.Grey}
          className={styles.upgradeButton}
          onClick={showUpgradeModal}
          size={ButtonSize.Small}
        >
          <UpgradeIcon size={IconSize.Standard} className={styles.upgradeIcon} /> Learn More
        </Button>
      </section>
      <QuoteViewedBuilderUpgradeModal
        isOpen={isUpgradeModalOpen}
        onRequestClose={handleCloseModal}
        useTransparentOverlay={false}
        translatedBuilderTerm={translatedBuilderTerm}
      />
    </>
  );
};
